import React from "react";

function SubjectInput({
  setSubjectOpen,
  subjectOpen,
  transtalteSubjectInput,
  TranslateSubjectStyles,
  data,
  subjectInputRef,
  setTranstalteSubjectInput,
}) {
  return (
    <label className="flex flex-col w-full font-opensans mb-5 cursor-pointer">
      <span className="text-[18px] font-opensans">Subject</span>
      <div
        className="  w-full  min-w-[280px] lg:w-[325px] font-arial group relative "
        ref={subjectInputRef}
      >
        <div
          className="h-[50px] px-4 bg-white border-quotetext-100 rounded-xl border w-full border-solid text-[#777777] mb-1 flex items-center justify-between"
          onClick={() => setSubjectOpen(!subjectOpen)}
        >
          <p>{transtalteSubjectInput.subcategory_name}</p>
          <span
            className={` text-quotetext-200 arrow ${
              subjectOpen ? "rotate-[316deg]" : "rotate-[135deg]"
            }  transition-all duration-200 ease-linear inline-block `}
          ></span>
        </div>
        {subjectOpen ? (
          <ul
            className="rounded-lg max-h-[350px] visible absolute z-50 w-full  overflow-y-scroll bg-white border-quotetext-100 border-solid border"
            style={TranslateSubjectStyles}
          >
            {data
              ? data?.subject?.map(({ subcategory_name, subcategory_id }) => (
                  <li
                    className="h-[50px] px-4 w-full text-[#777777] flex items-center justify-between"
                    key={subcategory_id}
                    onClick={() => {
                      setSubjectOpen(!subjectOpen);
                      setTranstalteSubjectInput({subcategory_name : subcategory_name , subcategory_id : subcategory_id})
                    }}
                  >
                    {subcategory_name}
                  </li>
                ))
              : null}
          </ul>
        ) : null}
      </div>
    </label>
  );
}

export default SubjectInput;
