import React, { useContext, useState, useEffect } from "react";
import DataProvider from "Context/DataContext";
import { useApplyPromoCode } from "hooks/Coupons/usePostPromoCode";
import PromoCodeImage from "assets/images/PromoCode.svg";
import EditIcon from "assets/icons/edit_icon.svg";
import { useRemovePromoCode } from "hooks/Coupons/useRemovePromoCode";
import Loader from "components/loader";
import moment from "moment";
import { useForm } from "react-hook-form";

function OrderSummary({ setStep, setSelected }) {
  const { thirdStepData, quoteId, setThirdStepData, setStepsData , stepsData  } =
    useContext(DataProvider);

  const [errorMessage, setErrorMessage] = useState("")
  const [response, setResponse] = useState("");
  const { status, mutateAsync } = useApplyPromoCode();
  const { status: Status, mutateAsync: MutateAsyncrounous } =
    useRemovePromoCode();

  const handlePromoSubmit = (data) => {
    const postData = {
      promocode: data.promocode,
      quote_id: quoteId,
    };
    mutateAsync(postData).then((val) => {
      setResponse(val.status);
      setErrorMessage(val.message)
      if(val.status === 1){
        setThirdStepData(val);
        setStepsData(val);
      }
    });
  };

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const handleRemoveSubmit = () => {
    const postData = {
      promocode: thirdStepData?.data?.quote?.coupon_code,
      quote_id: quoteId,
    };
    MutateAsyncrounous(postData).then((val) => {
      setStepsData(val);
      setThirdStepData(val);
      setResponse();
      reset();
    });
  };
  var targetLanguagePairString = "";
  stepsData?.data?.quote_items?.map(({target_language}, index) => {
      if (index !== (stepsData.data.quote_items.length-1)) {
        targetLanguagePairString += (target_language+", ");
      }else{
        targetLanguagePairString += target_language;
      }
  });

  const handleReturnStepThreeEdit = () => {
    localStorage.setItem("step", 1);
    setStep((preStep) => (preStep = "1"));
    setSelected((preSelect) => (preSelect = "1"));
  };

  return (
    <div className="w-full lg:w-5/12 flex items-center flex-col bg-white border-t-6 border-orange ">
      <div className="w-full xl:w-11/12 px-4 lg:px-4 py-5">
        <div className="w-full justify-between flex">
        <p className="w-6/12 font-opensans text-xl font-bold">ORDER SUMMARY</p>
        <div className="w-1/12" >
          <img src={EditIcon} onClick={handleReturnStepThreeEdit} />
        </div>
        </div>
        <div className="grid grid-cols-2 font-opensans mt-8 w-full gap-y-2 gap-x-6">
          <p className="text-base font-semibold">Language Pair:</p>
          <p className="font-normal text-base font-opensans">
            {stepsData?.data?.quote?.source_language || thirdStepData?.data?.quote?.source_language ? (
              stepsData?.data?.quote?.source_language || thirdStepData?.data?.quote?.source_language
            ) : (
              <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-full"></div>
            )}{" "}
            to <br />
            <span className="font-normal text-base font-opensans break-words">
              {" "}
              {targetLanguagePairString}
            </span>
          </p>
          {/* <div class="h-3 bg-quotetext-200 animate-pulse rounded-lg col-span-1 w-[100px]"></div> */}

          <p className="text-base font-semibold">Total words:</p>
          <p className="font-normal text-base font-opensans">
            {stepsData?.data?.quote?.total_words ? (
              stepsData?.data?.quote?.total_words
            ) : (
              <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-full"></div>
            )}
          </p>

          <p className="text-base font-semibold">Service type:</p>
          <p className={`font-normal text-base font-opensans`}>
            {stepsData?.data?.quote?.service_type === 1 ? "Best Price" : ""}
            {stepsData?.data?.quote?.service_type === 2
              ? "Fast Delivery"
              : ""}
          </p>

          <p className="text-base font-semibold">Delivery date:</p>
          <p className="font-normal text-base font-opensans">
            {stepsData?.data?.quote?.delivery_date ? (
              moment(stepsData?.data?.quote?.delivery_date).format(
                "MMMM DD, YYYY"
              )
            ) : (
              <div class="h-5 bg-quotetext-100 animate-pulse rounded-md col-span-1 w-full"></div>
            )}
          </p>
        </div>

        <div className="my-4">
          <p className="text-base font-semibold mb-4">Promotional Code:</p>
          {stepsData?.data?.quote?.coupon_code ? (
            <div className="w-full md:w-10/12 py-2 px-3 mx-auto flex items-center justify-between  border border-quotetext-100 rounded-lg">
              <p className="text-sm font-arial text-[#000C27] flex items-center ">
                {" "}
                <img
                  src={PromoCodeImage}
                  alt="loading..."
                  className="mr-1"
                />{" "}
                {stepsData?.data?.quote?.coupon_code}
              </p>
              <h2 className="text-base font-arial text-[#000C27] font-semibold flex items-center">
                <span
                  className="cursor-pointer ml-8 rounded-full inline-block text-center leading-none w-[17px] h-[17px] bg-[#DD4B39] text-white"
                  onClick={handleRemoveSubmit}
                >
                  x
                </span>
              </h2>
            </div>
          ) : (<>
            <form
              className="w-full flex items-center justify-center"
              onSubmit={handleSubmit(handlePromoSubmit)}
            >
              <input
                type="text"
                name="promocode"
                {...register("promocode",
                { required: true, 
                  onChange: (e) => e.target.value ? setErrorMessage("") : null}
                )}
                className="border border-quotetext-100 border-solid w-8/12 h-[46px] px-4 rounded-tl-lg rounded-bl-lg font-arial"
                placeholder="Enter promo code here"
              />

              <button
                type="submit"
                className={` bg-[#000C27]
                 w-4/12 h-[46px]  rounded-tr-lg rounded-br-lg text-white`}
              >
                {status === "loading" ? <Loader /> : "APPLY"}
              </button>
            </form>
             {errors.promocode && (
              <span className="text-xs font-opensans font-normal block  text-left w-full text-red">
               This field is required.
              </span>
            )}</>
          )}

          {!errors.promocode && response === 0 ? (
            <span className="text-red font-opensans text-xs text-left">
              {errorMessage}
            </span>
          ) : null}
        </div>
        <div className="w-full  h-[1px] bg-quotetext-100"></div>
        {stepsData?.data?.quote?.final_price_before_discount && 
        <div className="grid grid-cols-2 mt-1">
          <p className="text-new font-opensans">Sub Total:</p>
          <p className="text-new font-opensans text-right">
            {stepsData?.data?.quote?.final_price_before_discount}
          </p>
        </div>}
        {stepsData?.data?.quote?.coupon_amount && 
        <div className="grid grid-cols-2 mt-1">
          <p className="text-new font-opensans">Discount Amount:</p>
          <p className="text-new font-opensans text-right">
            -{stepsData?.data?.quote?.coupon_amount}
          </p>
        </div>}
        {stepsData?.data?.quote?.tax_amount && 
        <div className="grid grid-cols-2 mt-1">
          <p className="text-new font-opensans">Tax Amount:</p>
          <p className="text-new font-opensans text-right">
            {stepsData?.data?.quote?.tax_amount}
          </p>
        </div>}
        <div className="grid grid-cols-2 mt-1">
          <p className="font-bold text-2xl font-opensans">Grand Total:</p>
          <p className="font-bold text-2xl font-opensans text-right">
            {stepsData?.data?.quote?.final_price_after_tax}
          </p>
        </div>
      </div>
    </div>
  );
}

export default OrderSummary;
