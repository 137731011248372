import React from "react";
import FbReview from "assets/svg/fbreview";
import TrustPilot from "assets/svg/trustpilot";
import FeatureCustomer from "assets/svg/featuredcustomer";
import QuoteRevieCard from "./QuoteRevieCard";
import OptimizedImage from "hooks/OptimizedImage";

export default function QuoteReviews({ backgroundCol, styleOne }) {
  return (
    <section
      className={`${
        backgroundCol ? backgroundCol : "bg-quoteBackground"
      } py-20`}
    >
      <div className="container mx-auto px-4 max-w-6xl">
        <h2 className=" text-3xl md:text-4xl text-center font-semibold font-secondary mb-6">
          What Our Clients are Saying About Us
        </h2>
        <div className="flex flex-row justify-center mb-8 mt-10">
          <OptimizedImage src="https://tomedes.gumlet.io/assets/images/review_stars.svg" alt="review star" className="mt-5 mb-2 w-[343px] sm:w-[403px] h-[49px] sm:h-[59px]" />
        </div>
        <p className="text-base sm:text-lg text-center font-secondary font-medium mb-5">
          4.9 out of 5 stars (1,798 overall reviews)
        </p>
        {styleOne == 1 ? (
          <div className="mx-auto pt-10 pb-8">
            <div className="grid gap-4 grid-cols-1 place-items-center lg:grid-cols-3 lg:gap-0 lg:w-9/12 mx-auto">
              <OptimizedImage src="https://tomedes.gumlet.io/assets/images/g2_review.svg" alt="g2 reviews" className="w-[213px] h-[59px]" />
              <OptimizedImage src="https://tomedes.gumlet.io/assets/images/facebook_review.svg" alt="facebook reviews" className="w-[213px] h-[59px]"/>
              <OptimizedImage src="https://tomedes.gumlet.io/assets/images/featured_review.svg" alt="featured reviews" className="w-[213px] h-[59px]"/>
            </div>
          </div>
        ) : (
          <div className="mx-auto pt-10 pb-8">
            <div className="grid gap-4 grid-cols-1 place-items-center lg:grid-cols-3 lg:gap-0 lg:w-9/12 mx-auto">
              <FbReview />
              <TrustPilot />
              <FeatureCustomer />
            </div>
          </div>
        )}
        <QuoteRevieCard />
      </div>
    </section>
  );
}
