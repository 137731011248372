import React from "react";
import OptimizedImage from "hooks/OptimizedImage";

export default function QuoteWhatChooseUs({ backgroundCol, styleOne }) {
  return (
    <section
      className={`${
        backgroundCol ? backgroundCol : "why-choose-gradient"
      } pt-24 pb-16 relative`}
    >
      {styleOne == 1 ? (
        <div className="container mx-auto px-4 xl:px-0 lg:w-11/12">
          <div className="">
            <h2 className="text-4xl text-midBlue text-center leading-[60px] font-secondary font-semibold pl-4 mb-10">
              Why Choose Us?
            </h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-5 gap-y-7 gap-12 xl:gap-20 2xl:gap-0 justify-items-center">
            <div className="bg-white rounded-xl text-center w-72 xl:w-[228px] 2xl:w-60 px-8 py-12">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/XMLID_2_.svg"
                alt=""
                className="w-11 h-12 m-auto"
              />

              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                24/7 Customer
                <br />
                Service
                <span className="block invisible">for alignment</span>
              </h3>
              <ul className="text-left list-disc text-sm font-opensans text-darkBlue pl-2 flex flex-col gap-2">
                <li>Personal project manager</li>
                <li>Support and assistance via email, phone and chat</li>
                <li>
                  Working around the clock to provide the best, fastest service
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-xl text-center w-72 xl:w-[228px] 2xl:w-60 px-8 py-12">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/high-quality.svg"
                alt=""
                className="w-8 h-12 m-auto"
              />
              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                1-year
                <br />
                guarantee
                <span className="block invisible">for alignment</span>
              </h3>
              <ul className="text-left list-disc text-sm font-opensans text-darkBlue pl-2 flex flex-col gap-2">
                <li>Post-completion project support</li>
                <li>Comprehensive quality assurance</li>
              </ul>
            </div>
            <div className="bg-white rounded-xl text-center w-72 xl:w-[228px] 2xl:w-60 px-6 py-12">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/iso_certification.svg"
                alt="loading..."
                className="m-auto w-11 h-11"
              />

              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                ISO Certifications
                <br />
                for Enhanced
                <br />
                Translation Quality
              </h3>
              <ul className="text-left  list-disc text-sm font-opensans text-darkBlue px-2 pl-4 flex flex-col gap-2">
                <li>
                  <span className="font-bold">17100:2015</span>
                  <br />
                  Translation services quality
                </li>
                <li>
                  <span className="font-bold">9001:2015</span>
                  <br />
                  Quality management systems
                </li>
                <li>
                  <span className="font-bold">18587:2015</span>
                  <br />
                  Post-editing machine translation output
                </li>
              </ul>
            </div>
            <div className="bg-white rounded-xl text-center w-72 xl:w-[228px] 2xl:w-60 px-8 py-12">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/95k_business_customers.svg"
                alt="loading..."
                className="m-auto w-12 h-10"
              />

              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                95,000 Business
                <br />
                Customers
                <span className="block invisible">for alignment</span>
              </h3>
              <ul className="text-left  list-disc text-sm font-opensans text-darkBlue pl-2 flex flex-col gap-2">
                <li>Fortune 500 companies trust Tomedes translation agency</li>
                <li>
                  Official and selected
                  <span className="font-bold"> Google </span>
                  and
                  <span className="font-bold"> Android </span>
                  partner
                </li>
              </ul>
            </div>
            <div className="bg-white rounded-xl text-center w-72 xl:w-[228px] 2xl:w-60 px-8 py-12">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/98_customer_rate.svg"
                alt="loading..."
                className="m-auto w-11 h-11"
              />
              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                98% Customer
                <br />
                Satisfaction Rate
                <span className="block invisible">for alignment</span>
              </h3>
              <ul className="text-left  list-disc text-sm font-opensans text-darkBlue pl-2 flex flex-col gap-2">
                <li>
                  <span className="font-bold">4.9/5.0 </span>
                  rating out of 234 reviews on Trustpilot
                </li>
                <li>
                  <span className="font-bold">4.9/5.0 </span>
                  rating out of 1769 reviews on FeaturedCustomers
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : (
        <div className="container mx-auto px-4 lg:w-11/12">
          <div className="">
            <h2 className="text-4xl text-midBlue text-center leading-[60px] font-secondary font-semibold pl-4 ">
              Why Choose Us?
            </h2>
          </div>

          <div className="grid grid-cols-1 place-items-center place-content-center py-8 gap-10 md:grid-cols-2 md:gap-4 md:max-w-xl mx-auto lg:flex lg:flex-row lg:max-w-screen-xl lg:gap-0 lg:space-x-4 lg:items-stretch">
            <div className="bg-white rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/XMLID_2_.svg"
                alt=""
                className="w-11 h-12 m-auto"
              />

              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                24/7 Customer Service
              </h3>
              <ul className="text-left list-disc text-sm font-opensans text-darkBlue">
                <li>Personal project manager</li>
                <li>Support and assistance via email, phone and chat</li>
              </ul>
            </div>

            <div className="bg-white rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/high-quality.svg"
                alt=""
                className="w-8 h-12 m-auto"
              />
              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                1-year guarantee
              </h3>
              <ul className="text-left list-disc text-sm font-opensans text-darkBlue">
                <li>Post-completion project support</li>
                <li>Comprehensive quality assurance</li>
              </ul>
            </div>

            <div className="bg-white rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/Icon-ionic-ios-time.svg"
                alt=""
                className="w-9 h-9 m-auto"
              />

              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                Fast Delivery
              </h3>
              <ul className="text-left  list-disc text-sm font-opensans text-darkBlue">
                <li>Availability for urgent orders</li>
                <li>
                  Working around the clock to provide the best, fastest service
                </li>
              </ul>
            </div>

            <div className="bg-white rounded-xl text-center px-10 py-12 mx-10 md:mx-0 lg:flex-1">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/suitcase.svg"
                alt=""
                className="w-10 h-[34px] m-auto"
              />

              <h3 className="text-base text-darkBlue font-secondary font-semibold py-4">
                95,000 Business Customers
              </h3>
              <ul className="text-left  list-disc text-sm font-opensans text-darkBlue">
                <li>Fortune 500 companies trust Tomedes translation agency</li>
                <li>Official and selected Google and Android partner</li>
              </ul>
            </div>
          </div>
        </div>
      )}
    </section>
  );
}
