import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const RemovePromo = (postData) => {
  return http().post(endpoints.coupons.removePromoCode, postData);
};

export function useRemovePromoCode() {
  return useMutation(RemovePromo, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
