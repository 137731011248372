import React, { useState, useContext } from "react";
import { useSubmitSubscribe } from "hooks/Forms/useSubmitSubscribe";
import Loader from "components/loader";
import { useForm } from "react-hook-form";
import DataProvider from "Context/DataContext";
import ThankYouPopup from "components/popup/thankYouPopup";
import formCommonPostData from "utils/form-common-post-data";
import { useLocation } from "@reach/router";
import {errorMessageEmail, patternEmail} from "utils/form-validation";

export default function SaveQuotePopUp({
  open,
  setOpen,
  serviceType,
  setsOpen,
}) {
  const { quoteId } = useContext(DataProvider);

  const [thankOpen, setThankOpen] = useState(false);
  const location = useLocation();
  const { status, mutateAsync } = useSubmitSubscribe();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      service_type: serviceType,
      email: data.email,
      quote_id: quoteId,
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    mutateAsync(postData).then((response) => {
      reset();
      setThankOpen(true);
      setsOpen(false);
    });
  };

  return (
    <>
      {open && (
        <React.Fragment>
          <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none font-primary ">
            <div className="relative  my-6 mx-auto max-w-[458px]  w-11/12 sm:w-full ">
              <form
                className="border-0 rounded-lg shadow-lg relative max-h-[301px] h-full flex flex-col w-full bg-white outline-none focus:outline-none py-10 px-8"
                onSubmit={handleSubmit(onSubmit)}
              >
                <span
                  className="cursor-pointer w-6 h-6 rounded-full border-2 text-black absolute top-4 right-3 text-center"
                  onClick={() => setsOpen(false)}
                >
                  X
                </span>
                <p className="text-[22px] font-secondary font-medium text-center mb-5">
                  Enter your email and we will send the quote to you
                </p>
                <input
                  className="outline-none h-[46px] px-4 w-full font-arial font-normal text-base text-quotetext-200 border border-quotetext-100 rounded-lg"
                  placeholder="Email address"
                  type="email"
                  name="email"
                  {...register("email", {
                    required: true,
                    pattern: patternEmail,
                  })}
                />
                <p className="text-left text-xs font-arial text-quotetext-200 mt-[2px] mb-1.5">
                  *will only be used to contact you once
                </p>
                {errors.email && (
                  <span className="text-xs font-opensans font-normal text-left w-full text-red">
                  {errorMessageEmail}
                  </span>
                )}
                <input type="hidden" id="zc_gad" name="zc_gad" value="" />
                <button
                  className="mt-4 w-[176px] h-[44px] text-white bg-orange rounded-full font-bold font-opensans text-lg mx-auto"
                  type="submit"
                >
                  {status === "loading" ? <Loader /> : "Submit"}
                </button>
              </form>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </React.Fragment>
      )}
      <ThankYouPopup
        open={thankOpen}
        setOpen={setThankOpen}
        setOpenPopUp={setOpen}
      />
    </>
  );
}
