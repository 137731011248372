import React from "react";
import OptimizedImage from "hooks/OptimizedImage";
import Audrey from "assets/audrey.png";
function QuoteCard() {
  return (
    <div className="w-full flex items-center justify-center bg-white py-5 px-4 mt-4 lg:mt-10 flex-col sm:flex-row">
      <div className="w-full flex flex-col sm:pl-6 pt-3 sm:pt-0 text-center sm:text-left">
        <p className="font-secondary text-2xl text-midBlue font-semibold mb-2">
          Do you need help getting a quote ?
        </p>
        <h2 className="font-secondary text-sm sm:text-lg text-midBlue">
          Contact us and we'll get you sorted out
        </h2>
      </div>
      <div className="w-full flex items-center justify-center  lg:flex-row flex-row sm:flex-col mt-5 sm:mt-0 gap-[40px]">
        <img
          src={Audrey}
          alt="loading..."
          className="w-[85px] h-[85px]  rounded-full"
          style={{ borderRadius: "100%" }}
        />
        <div>
          <p className="hidden sm:block text-[#FF7B16] text-2xl leading-7 font-semibold">
            Audrey Mangalinao
          </p>{" "}
          <p className="hidden sm:block text-[#000C27] font-primary leading-7 font-normal text-base">
            {" "}
            Customer Success Deputy Manager
          </p>
          <p className="sm:hidden block text-[#000C27] font-primary leading-7 font-normal text-base">
            <span className="text-[#FF7B16] text-2xl leading-7">
              Audrey Mangalinao
            </span>{" "}
            -
            <br />
            Customer Success Deputy Manager
          </p>
          <h2 className="hidden sm:block ont-primary font-normal text-[#A2A8B7] text-sm ">
            <a href="tel:+19852390142">US: +1 985 239 0142</a> |{" "}
            <a href="tel:+441615096140">UK: +44 (0)16 1509 6140</a>
          </h2>
          <h2 className="sm:hidden font-primary font-normal text-[#A2A8B7] text-sm py-2">
            <a href="tel:+19852390142">US: +1 985 239 0142</a> |
            <br />
            <a href="tel:+441615096140">UK: +44 (0)16 1509 6140</a>
          </h2>
          <p className="font-primary font-normal text-[#A2A8B7] text-sm">
            <a href="mailto:support@tomedes.com">support@tomedes.com</a>
          </p>
        </div>
      </div>
    </div>
  );
}

export default QuoteCard;
