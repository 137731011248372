import React, { useContext, useState, useEffect } from "react";
import OrderSummary from "../thirdComp/OrderSummary";
import DataProvider from "Context/DataContext";
import SignInPopUp from "../thirdComp/SignInPopUp";
import FileUploadPopUp from "components/popup/quotePopUp/fileUploadPopUp";
import { useForm } from "react-hook-form";
import { UseDeleteFiles } from "hooks/Quotes/useDeleteFile";
import { usePostFileUpload } from "hooks/Quotes/usePostFileUpload";
import { usePostPlaceOrder } from "hooks/Quotes/usePostPlaceOrder";
import { usePostCheckoutAsGuest } from "hooks/Quotes/usePostCheckoutAsGuest";
import Loader from "components/loader";
import { navigate } from "gatsby";
import QuoteCard from "../firstComp/QuoteCard";
import { useLocation } from "@reach/router";
import getCookie from "utils/getCookie";
import formCommonPostData from "utils/form-common-post-data";
import { errorMessage, errorMessageEmail } from "utils/form-validation";

function ThirdStep({ setStep, setSelected }) {
  const { status, mutateAsync } = usePostPlaceOrder();
  const [openFilePopUp, setFilePopUp] = useState(false);
  const [emailFeild, setEmailFeild] = useState(true);
  const [project, setProject] = useState("");
  const location = useLocation();
  const { guestcheckoutStatus, mutateAsync: GuestCheckout } =
    usePostCheckoutAsGuest();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const {
    thirdStepData,
    fileValue,
    quoteId,
    setfileValue,
    setThirdStepData,
    token,
    userEmail,
    setUserMail,
    setToken,
    accountInfo,
    toggleOrder,
    settoggleOrder,
    setAccountInfo,
    LocalObject,
    setStepsData,
  } = useContext(DataProvider);

  useEffect(() => {
    const userData = LocalObject.localStorage.getItem("acc_info");
    if (userData) {
      setUserMail(JSON.parse(userData).user?.email);
      setProject(localStorage.getItem("project_name"));
      setEmailFeild(false);
    }
  }, [token]);

  const [open, setOpen] = useState(false);

  const { status: fileStatus, mutateAsync: FileMutateAsync } =
    usePostFileUpload();

  const onFileUpload = (e) => {
    const files = e.target.files;
    const formData = new FormData();
    formData.append("document", files[0]);
    formData.append("quote_id", quoteId);
    formData.append("source", getCookie("utm_source"));
    formData.append("utm_campaign", getCookie("utm_campaign"));
    formData.append("utm_agid", getCookie("utm_agid"));
    formData.append("utm_term", getCookie("utm_term"));
    formData.append("creative", getCookie("creative"));
    formData.append("device", getCookie("device"));
    formData.append("placement", getCookie("placement"));
    formData.append("utm_medium", getCookie("utm_medium"));
    FileMutateAsync(formData).then((response) => {
      const FileResponse = response.data.quote_documents;
      setfileValue([...FileResponse]);
      setStepsData(response);
    });
  };

  const handleDeleteFile = (id) => {
    UseDeleteFiles(id).then((res) => {
      setStepsData(res);
      setfileValue(res.data.quote_documents);
    });
  };

  const handleReturn = () => {
    localStorage.setItem("step", 2);
    setStep((preStep) => (preStep = "2"));
    setSelected((preSelect) => (preSelect = "2"));
  };

  const logOut = () => {
    setToken(LocalObject.localStorage.removeItem("access_token"));
    setAccountInfo(LocalObject.localStorage.removeItem("acc_info"));
    setUserMail(LocalObject.localStorage.removeItem("email"));
    setEmailFeild(true);
  };

  const handleCheckoutasGuest = (data) => {
    const postData1 = {
      quote_id: quoteId,
      customer_email: localStorage.getItem("email"),
      project_name: localStorage.getItem("project_name"),
      special_instruction: localStorage.getItem("special_instructions"),
      pagelink: location.href,
    };
    const postData = { ...postData1, ...formCommonPostData() };
    GuestCheckout(postData).then((response) => {
      setThirdStepData(response);
      if (response.status === 1) {
        localStorage.setItem("order_id", response.data.quote.order_number);
        localStorage.removeItem("quote_id");
        localStorage.removeItem("email");
        localStorage.removeItem("project_name");
        localStorage.removeItem("special_instructions");
        localStorage.removeItem("step");
        return navigate("/quote/checkout");
      } else {
        alert(response.message);
      }
    });
  };

  const handleAfterSignin = () => {
    if (localStorage.getItem("acc_info")) {
      const userData = LocalObject.localStorage.getItem("acc_info");
      const postData1 = {
        quote_id: quoteId,
        customer_email: localStorage.getItem("email"),
        project_name: localStorage.getItem("project_name"),
        special_instruction: localStorage.getItem("special_instructions"),
        customer_id: JSON.parse(userData)?.user?.id,
        pagelink: location.href,
      };
      const postData = { ...postData1, ...formCommonPostData() };
      mutateAsync(postData).then((response) => {
        setThirdStepData(response);
        if (response.status === 1) {
          localStorage.setItem("order_id", response.data.quote.order_number);
          localStorage.removeItem("quote_id");
          localStorage.removeItem("email");
          localStorage.removeItem("project_name");
          localStorage.removeItem("special_instructions");
          localStorage.removeItem("step");
          return navigate("/quote/checkout");
        } else {
          alert(response.message);
        }
      });
    }
  };

  const handlePlaceOrder = (data) => {
    if (localStorage.getItem("acc_info")) {
      const userData = LocalObject.localStorage.getItem("acc_info");
      const postData1 = {
        quote_id: quoteId,
        customer_email: localStorage.getItem("email"),
        project_name: localStorage.getItem("project_name"),
        special_instruction: localStorage.getItem("special_instructions"),
        customer_id: JSON.parse(userData)?.user?.id,
        pagelink: location.href,
      };
      const postData = { ...postData1, ...formCommonPostData() };
      mutateAsync(postData).then((response) => {
        setThirdStepData(response);
        if (response.status === 1) {
          localStorage.setItem("order_id", response.data.quote.order_number);
          localStorage.removeItem("quote_id");
          localStorage.removeItem("email");
          localStorage.removeItem("project_name");
          localStorage.removeItem("special_instructions");
          localStorage.removeItem("step");
          return navigate("/quote/checkout");
        } else {
          alert(response.message);
        }
      });
    } else {
      const postData1 = {
        quote_id: quoteId,
        customer_email: localStorage.getItem("email"),
        project_name: localStorage.getItem("project_name"),
        special_instruction: localStorage.getItem("special_instructions"),
        password: data.password,
        pagelink: location.href,
      };
      const postData = { ...postData1, ...formCommonPostData() };
      mutateAsync(postData).then((response) => {
        setThirdStepData(response);
        if (response.status === 1) {
          localStorage.setItem("order_id", response.data.quote.order_number);
          localStorage.removeItem("quote_id");
          localStorage.removeItem("email");
          localStorage.removeItem("project_name");
          localStorage.removeItem("special_instructions");
          localStorage.removeItem("step");
          return navigate("/quote/checkout");
        } else {
          alert(response.message);
        }
      });
    }
  };
  if (thirdStepData === {}) return null;
  return (
    <>
      <div className="flex items-center justify-center w-full">
        <form
          className="flex  justify-center flex-wrap lg:flex-nowrap flex-col xl:gap-10 w-full 2xl:w-[65%] xl:w-9/12  max-w-screen-2xl px-4 xl:px-0"
          onSubmit={handleSubmit(handlePlaceOrder)}
        >
          <p className="font-secondary font-semibold text-4xl py-5 text-center">
            Create an Account
          </p>
          <div className="w-full flex items-center justify-center flex-wrap lg:flex-nowrap flex-col-reverse sm:flex-row">
            <div
              className={`lg:w-[55%] w-full ${
                localStorage.getItem("acc_info") ? "self-start" : ""
              }`}
            >
              <SignInPopUp
                open={open}
                setOpen={setOpen}
                setEmailFeild={setEmailFeild}
                settoggleOrder={settoggleOrder}
                handleAfterSignin={handleAfterSignin}
                placeOrder
                handleAfterSigninCondition
              />
              <div className="flex items-center lg:items-start flex-col w-full mt-5 sm:mt-0">
                <div className="w-full flex justify-between flex-col sm:flex-row items-start sm:items-center">
                  <p className="text-xl font-opensans font-bold mb-4 sm:mb-12">
                    {LocalObject.localStorage.getItem("access_token") ||
                    token ? (
                      <>
                        {" "}
                        You're signed in as{" "}
                        <span className="cursor-pointer text-orange underline">
                          {LocalObject.localStorage.getItem("email")
                            ? LocalObject.localStorage.getItem("email")
                            : accountInfo?.user?.username}{" "}
                        </span>{" "}
                      </>
                    ) : (
                      <>
                        Have an account?{" "}
                        <span
                          onClick={() => setOpen(true)}
                          className="cursor-pointer text-orange underline"
                        >
                          Sign in
                        </span>
                        {"  "}
                        here.
                      </>
                    )}
                  </p>
                  {LocalObject.localStorage.getItem("access_token") || token ? (
                    <h3
                      className="text-lg font-opensans font-semibold underline mb-12  cursor-pointer hover:text-orange md:mr-9"
                      onClick={logOut}
                    >
                      Sign Out
                    </h3>
                  ) : (
                    <span
                      className="text-lg font-opensans font-semibold underline mb-12  cursor-pointer md:mr-9"
                      onClick={handleCheckoutasGuest}
                    >
                      Continue as Guest
                    </span>
                  )}
                </div>
                {!localStorage.getItem("acc_info") && (
                  <div className="flex  xl:gap-6 lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:justify-between mb-5">
                    <label className="flex flex-col w-full font-opensans">
                      <span className="text-xl font-opensans">
                        {" "}
                        Email address*
                      </span>
                      <input
                        type="email"
                        name="email"
                        value={localStorage.getItem("email")}
                        disabled={true}
                        placeholder="e.g. example@example.com"
                        // {...register("email", { required: true })}
                        className="h-[50px]  w-full  bg-white px-4 rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-1"
                        required
                      />
                      {errors.email && (
                        <span className="text-xs font-opensans font-normal text-left w-full text-red">
                          {errorMessageEmail}
                        </span>
                      )}
                    </label>
                  </div>
                )}
                <FileUploadPopUp
                  setFilePopUp={setFilePopUp}
                  openFilePopUp={openFilePopUp}
                  onFileUpload={onFileUpload}
                  fileValue={fileValue}
                  fileStatus={fileStatus}
                  handleDeleteFile={handleDeleteFile}
                />
                {!localStorage.getItem("acc_info") && (
                  <div className="flex flex-wrap lg:flex-nowrap w-full 2xl:w-11/12 lg:w-11/12 justify-center lg:justify-between mb-5">
                    <label className="flex flex-col w-full font-opensans">
                      <span className="text-xl font-opensans"> Password</span>
                      <input
                        type="password"
                        name="password"
                        placeholder="enter password"
                        {...register("password", { required: true })}
                        className="h-[50px]  w-full  bg-white px-4 rounded-xl border border-[#CCCCCC] border-solid text-[#777777] mb-1"
                      />
                      {errors.password && (
                        <span className="text-xs font-opensans font-normal text-left w-full text-red">
                          {errorMessage}
                        </span>
                      )}
                    </label>
                  </div>
                )}
                {!localStorage.getItem("acc_info") && (
                  <div className="font-arial text-base w-full 2xl:w-11/12 lg:w-11/12  text-black sm:mb-5 lg:mb-0">
                    By registering to this website, you accept the{" "}
                    <span className="text-lightBlue">
                      <a href="/user-agreement" target="_blank">
                        {" "}
                        Terms & Conditions{" "}
                      </a>
                    </span>{" "}
                    and{" "}
                    <span className="text-lightBlue">
                      <a href="/privacy" target="_blank">
                        Privacy Policy
                      </a>
                    </span>{" "}
                    of Tomedes.
                  </div>
                )}
              </div>
            </div>
            <OrderSummary setStep={setStep} setSelected={setSelected} />
          </div>
          <div className="w-full  h-[1px] bg-quotetext-100 mt-3 hidden sm:block"></div>
          <div className="flex items-center justify-between w-full  mb-3 lg:mb-0 mt-8 xl:mt-0 flex-col-reverse sm:flex-row gap-5 sm:gap-0">
            <div className="flex flex-col sm:flex-row justify-center sm:justify-between">
              <p
                className="text-lg text-quotetext-200 hover:text-orange underline cursor-pointer"
                onClick={handleReturn}
              >
                {"<Return to Order Details"}
              </p>
            </div>
            <button
              className="text-xl font-medium text-white font-secondary sm:max-w-[211px] w-full h-[53px] rounded-full bg-orange "
              type="submit"
            >
              {status === "loading" ? <Loader /> : "Place Order"}
            </button>
          </div>
        </form>
      </div>
      <div className="w-full 2xl:w-[65%] xl:w-10/12  max-w-screen-2xl px-4 xl:px-0 sm:mt-5">
        <QuoteCard />
      </div>
    </>
  );
}

export default ThirdStep;
