import Quote from "components/Quote/index";
import QuoteHeader from "components/Quote/Quotelayout/QuoteHeader";
import SEO from "components/seo";
import React from "react";

function index() {
  const description = " Submit your work and Get a free translation quote. We will respond in under 15 minutes. Get started!";
  const title = "Free Translation Quote - 120 languages | Tomedes";
  const keywords = "translation, professional translation, translation service, language translation, translation company, translation agency";
  return (
    <>
      <SEO title={title} description={description} keywords={keywords} slug="/quote" />
      <QuoteHeader />
      <Quote />
    </>
  );
}

export default index;
