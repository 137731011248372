import React, { useState, useEffect, useContext } from "react";
import FirstStep from "./firstComp/FirstStep";
import SecondStep from "./secondComp/SecondStep";
import ThirdStep from "./thirdComp/ThirdStep";
import QuoteWhatChooseUs from "./QuoteReusableComp/QuoteWhatChooseUs";
import QuoteTranslateAgency from "./QuoteReusableComp/QuoteTranslateAgency";
import QuoteReviews from "./QuoteReusableComp/QuoteReviews";
import DataProvider from "Context/DataContext";
import FourthStep from "./fourthStep/FourthStep";
import axios from "axios";
import { API_ROOT } from "utils/http";
import Footer from "components/layout/footer";
import IsoCertifications from "./IsoCertifications";

function Quote() {
  const [step, setStep] = useState(null);
  const [selected, setSelected] = useState(null);
  const [context, setContext] = useState(null);
  const [hasQuoteData, setHasQuoteData] = useState(false);
  const { LocalObject, setQuoteId, setStepsData, setfileValue } =
    useContext(DataProvider);

  const quote_id =
    LocalObject.localStorage !== undefined
      ? LocalObject.localStorage.getItem("quote_id")
      : "";

  const saveQuoteId = async () => {
    await axios
      .get(`${API_ROOT}/api/v1/get-quote-info?quote_id=${quote_id}`)
      .then((res) => {
        if (res.data.success) {
          setHasQuoteData(true);
          setQuoteId(quote_id);
          setStepsData(res.data);
          setContext(res.data.data);
          const FileResponse = res.data.data.quote_documents;
          setfileValue([...FileResponse]);
        }
      });
  };

  useEffect(() => {
    const lastStep =
      LocalObject.localStorage !== undefined
        ? LocalObject.localStorage.getItem("step")
        : "";

    if (quote_id && !hasQuoteData) {
      saveQuoteId();
    }

    if (lastStep && quote_id) {
      setSelected(lastStep);
      setStep(lastStep);
    } else {
      setSelected("1");
      setStep("1");
    }
  }, [setQuoteId]);

  const useExperiment = (experimentId) => {
    const [variant, setVariant] = useState();
    useEffect(() => {
      (async () => {
        // if (window.dataLayer) {
        //   await window.dataLayer.push({ event: "optimize.activate" });
        // }
        const intervalId = setInterval(() => {
          if (window.google_optimize !== undefined) {
            // Set the variant to the state.
            setVariant(window.google_optimize.get(experimentId));
            clearInterval(intervalId);
          }
        }, 100);
      })();
    });
    return variant;
  };
  const variant = useExperiment("R4MoC5YVS-GAugOccrnSyw");
  // console.log(variant);
  // const variant_mobile = useExperiment("qTbb91wkRi-Z-X1ZByebNw");
  return (
    <>
      <div className="flex items-center justify-center bg-quoteBackground pt-24 pb-10 flex-col">
        <div className="flex items-center justify-center mb-14 font-secondary flex-col md:w-7/12 w-11/12">
          <div className="w-[89.33%] h-1 mb-2  flex items-center justify-between">
            <div className="w-5">
              {" "}
              <p className=" h-5 rounded-full border border-orange bg-white leading-[1.2] text-center">
                <span className="inline-block w-3 h-3 bg-orange rounded-full "></span>
              </p>
            </div>

            <div className="w-full flex justify-between items-center">
              <p
                className={`${
                  selected === "2" || selected === "3" || selected === "4"
                    ? "bg-orange"
                    : "bg-quotetext-700"
                }  w-full h-1 `}
              ></p>
              <p
                className={`${
                  selected === "2" || selected === "3" || selected === "4"
                    ? "border-orange"
                    : "border-quotetext-700"
                }  w-5 h-5 rounded-full border  bg-white leading-[1.2] text-center`}
              >
                <span
                  className={`${
                    selected === "2" || selected === "3" || selected === "4"
                      ? "bg-orange"
                      : "bg-quotetext-700"
                  }  inline-block w-3 h-3  rounded-full `}
                ></span>
              </p>
            </div>
          </div>
          <div className="text-center cursor-pointer flex items-center justify-between  w-full">
            <p
              className={` transition-all duration-700 font-opensans font-semibold text-quotetext-500 md:text-base text-[12px] `}
            >
              Step 1: Project Details
            </p>
            {/* <p
              className={` transition-all duration-700 font-opensans font-semibold text-quotetext-500 md:text-base text-[12px]`}
            >
              Step 2: Purchase Options
            </p> */}
            <p
              className={` transition-all duration-700 font-opensans font-semibold text-quotetext-500 md:text-base text-[12px]`}
            >
              Step 2: Place Order
            </p>
          </div>
        </div>
        {step === "1" ? (
          <FirstStep
            setStep={setStep}
            step={step}
            context={context}
            setContext={setContext}
            selected={selected}
            setSelected={setSelected}
          />
        ) : // ) : step === "2" ? (
        //   <SecondStep
        //     context={context}
        //     setStep={setStep}
        //     step={step}
        //     setSelected={setSelected}
        //   />
        step === "2" ? (
          <ThirdStep setSelected={setSelected} setStep={setStep} />
        ) : step === "4" ? (
          <FourthStep setSelected={setSelected} setStep={setStep} />
        ) : null}
        {/* <SuccessQuote/> */}
      </div>
      <QuoteWhatChooseUs
        styleOne={variant}
        // styleOne="1"
      />
      <QuoteTranslateAgency
        styleOne={variant}
        // styleOne="1"
      />
      <IsoCertifications
        styleOne={variant}
        // styleOne="1"
      />
      <QuoteReviews
        styleOne={variant}
        // styleOne="1"
      />
      <Footer />
    </>
  );
}

export default Quote;
