import React from "react";

function ServiceInput({
  setServiceOpen,
  serviceOpen,
  transtalteServiceInput,
  TranslateServiceStyles,
  serviceInputRef,
  data,
  setTranstalteServiceInput,
}) {
  return (
    <label className="flex flex-col w-full font-opensans mb-5 cursor-pointer">
      <span className="text-[18px] font-opensans">Service</span>
      <div
        className=" w-full  min-w-[280px] lg:w-[325px] font-arial group relative "
        ref={serviceInputRef}
      >
        <div
          className="h-[50px] px-4 bg-white border-quotetext-100 rounded-xl border w-full border-solid text-[#777777] mb-1 flex items-center justify-between"
          onClick={() => setServiceOpen(!serviceOpen)}
        >
          <p>{transtalteServiceInput.category_name }</p>
          <span
            className={` text-quotetext-200 arrow ${
              serviceOpen ? "rotate-[316deg]" : "rotate-[135deg]"
            }  transition-all duration-200 ease-linear inline-block `}
          ></span>
        </div>
        {serviceOpen ? (
          <ul
            className="rounded-lg max-h-[350px] visible absolute z-50 w-full  overflow-y-scroll bg-white border-quotetext-100 border-solid border"
            style={TranslateServiceStyles}
          >
            {data
              ? data?.service?.map(({ category_name, category_id }) => (
                  <li
                    className="h-[50px] px-4 w-full text-[#777777] flex items-center justify-between"
                    key={category_id}
                    onClick={() => {
                      setServiceOpen(!serviceOpen);
                      setTranstalteServiceInput({category_name : category_name, category_id : category_id})
                    }}
                  >
                    {category_name}
                  </li>
                ))
              : null}
          </ul>
        ) : null}
      </div>
    </label>
  );
}

export default ServiceInput;
