import { useMutation } from "react-query";
import { endpoints } from "utils/endpoints";
import http from "utils/http";

const applyPromo = (postData) => {
  return http().post(endpoints.coupons.applyPromoCode, postData);
};

export function useApplyPromoCode() {
  return useMutation(applyPromo, {
    onError: (errorData) => {
      const errorMessage = errorData?.error?.message || errorData?.message;
      alert(errorMessage);
    },
  });
}
