import React from "react";
import OptimizedImage from "hooks/OptimizedImage";

export default function QuoteRevieCard() {
  return (
    <div>
      <div className="mainContainer w-full flex flex-col lg:flex-row lg:flex-grow-0 mx-auto justify-center text-white my-4 lg:gap-x-6 ">
        <div className="cardOne  bg-white rounded-2xl p-6 lg:w-22REM w-full lg:h-auto h-80 relative mb-6 lg:mb-0">
          <p className="font-medium text-lg text-black">
            "Speedy return & <br /> reasonably priced"
          </p>
          <p className="mt-6 font-light text-light text-sm tracking-wider text-black">
            A translation company well equipped with knowledge of hundreds of
            different languages - speedy return & reasonably priced.
          </p>
          <div className="name absolute bottom-0 mb-4 pb-6">
            <OptimizedImage src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/desktop/bbc_logo.svg" alt="bbc" className="w-16 h-5 mb-2" />
            <p className="text-black font-secondary">Lindsay Katherine</p>
          </div>
        </div>
        <div className="cardTwo bg-white rounded-2xl lg:w-22REM w-full p-6 relative mb-6 lg:mb-0">
          <p className="font-medium text-lg text-black">
            "Tomedes has been nothing but kind, knowledgeable and efficient!"
          </p>
          <p className="mt-6 font-light text-sm mb-28 tracking-wider text-black">
            Tomedes has been nothing but kind, knowledgeable and efficient! At
            first, we utilized their translation services to translate a few
            sensitive documents, and mostly recently used interpretation
            services to assist with HR needs. Everything was seamless! I trusted
            Tomedes so much they're now working with other departments in my
            company. We're working on an even bigger project: interpreting a
            multi-language Zoom meeting!
          </p>
          <div className="name absolute bottom-0 mb-4 pb-6">
            <OptimizedImage src="https://tomedes.gumlet.io/assets/images/1.png" alt="" className="w-9 h-9 mb-2" />
            <p className="text-black font-secondary">Ashrena Ali</p>
          </div>
        </div>
        <div className="cardThree bg-white rounded-2xl p-6 w-full relative lg:w-22REM">
          <p className="text-lg font-medium text-black">
            "Efficient and very good customer service."
          </p>
          <p className="mt-6 font-light text-sm mb-28 tracking-wider text-black">
            Efficient and very good customer service. The Tomedes team helped us
            translate our core content into Turkish, Korean, Polish, and
            Italian. They were very responsive and quick to help us address any
            issues related to timeline and quality of translation. It was
            helpful to get spreadsheets from them to track their progress. I
            would recommend the Tomedes team to any computer science curriculum
            providers.
          </p>
          <div className="name absolute bottom-0 mb-4 pb-6">
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/code.svg" alt="code" className="w-9 h-9 mb-2" />
            <p className="text-black font-secondary">Jorge Castro</p>
          </div>
        </div>
      </div>
    </div>
  );
}
