import React from "react";
import OptimizedImage from "hooks/OptimizedImage";
export default function QuoteTranslateAgency({
  BackgroundCol,
  content,
  head,
  colorHead,
  lastHeadText,
  styleOne,
}) {
  return (
    <section
      className={`${
        BackgroundCol ? BackgroundCol : "translation_agaency_quote"
      } py-24 relative flex items-center justify-center`}
    >
      <div className="container flex items-center justify-center max-w-screen-2xl w-full xl:w-10/12 flex-col px-4">
        <h2 className=" text-3xl md:text-4xl text-center font-semibold font-secondary leading-10">
          A Translation Agency you can Trust
        </h2>
        <p className=" font-opensans text-center py-4 leading-8 lg:px-10 lg:max-w-5xl lg:mx-auto my-2 text-lg">
          95,000 clients have entrusted Tomedes with their business. These
          include private individuals, small to medium businesses, and the
          largest corporations, leading brands, national and local governments.
          But most importantly: we're here for you. Right now.
        </p>
        {styleOne == 1 ? (
          <div className="mx-auto pt-10 text-center">
            <div className="grid gap-12 max-w-lg mx-auto  grid-cols-2 md:grid-cols-3 md:gap-10 lg:flex lg:flex-wrap lg:justify-center lg:space-x-6 lg:space-y-4 lg:max-w-4xl place-items-center place-content-center xl:grid xl:grid-cols-7 xl:gap-x-5">
              <div className="self-end">
                <OptimizedImage
                  src="https://tomedes.gumlet.io/assets/clients/amazon.svg"
                  alt="amazon"
                  className="w-24 h-auto"
                />
              </div>
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/google.svg"
                alt="google"
                className="w-20 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/microsoft.svg"
                alt="microsoft"
                className="w-24 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/youtube.svg"
                alt="youtube"
                className="w-24 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg"
                alt="bloomberg"
                className="w-24 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/spotify.svg"
                alt="spotify"
                className="w-20 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/logo-salesforce.svg"
                alt="salesforce"
                className="w-[53px] h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/wix.svg"
                alt="wix"
                className="w-20 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/hsbc.svg"
                alt="hsbc"
                className="w-24 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/images/canon.svg"
                alt="canon"
                className="w-24 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/mgm.png"
                alt="mgm"
                className="w-11 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/code.svg"
                alt="code"
                className="w-9 h-auto"
              />
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/sap.svg"
                alt="sap"
                className="w-12 h-auto"
              />
               <OptimizedImage
                src="https://tomedes.gumlet.io/assets/logooecd_en_black.webp"
                alt="oecd"
                className="w-24 h-auto"
              />
            </div>
          </div>
        ) : (
          <div className="mx-auto pt-10 text-center">
            <div className="grid gap-12 max-w-lg mx-auto  grid-cols-2 md:grid-cols-3 md:gap-10 lg:flex lg:flex-wrap lg:justify-center lg:space-x-6 lg:space-y-4 lg:max-w-4xl place-items-center place-content-center">
            <div className="self-end">
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/amazon.svg" alt="amazon" className="w-24 h-auto" />
            </div>
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/google.svg" alt="google" className="w-20 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/microsoft.svg" alt="microsoft" className="w-24 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/youtube.svg" alt="youtube" className="w-24 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg" alt="bloomberg" className="w-24 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/spotify.svg" alt="spotify" className="w-20 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/wix.svg" alt="wix" className="w-20 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/hsbc.svg" alt="hsbc" className="w-24 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/images/canon.svg" alt="canon" className="w-24 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/mgm.png" alt="mgm" className="w-11 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/code.svg" alt="code" className="w-9 h-auto" />
            <OptimizedImage src="https://tomedes.gumlet.io/assets/clients/sap.svg" alt="sap" className="w-12 h-auto" />
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
