import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { usePostSignUp } from "hooks/Auth/useSignUpForm";
import Loader from "components/loader";
import formCommonPostData from "utils/form-common-post-data";
import {
  errorMessage,
  errorMessageEmail,
  patternEmail,
} from "utils/form-validation";

function SignUpPopUp({ setOpen, setToggle }) {
  const [checkSignUp, setCheckSignUp] = useState("");
  const { status, mutateAsync } = usePostSignUp();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    const postData1 = {
      email: data.email,
      username: data.name,
      password: data.password,
    };
    const postData = { ...postData1, ...formCommonPostData() }
    mutateAsync(postData).then((res) => {
      setCheckSignUp(res.message);
      if (res.message === "Resigered Successfully") {
        reset();
      }
    });
    //
  };
  const styles = {
    background: "transparent linear-gradient(180deg, #F1F6F8 0%, #F9FAFB 61%, #FFFFFF 100%) 0% 0% no-repeat padding-box",
  };
  return (
    <form style={styles} className="relative  my-6 sm:mx-auto max-w-[482px] w-11/12 sm:w-full px-2 sm:px-10  py-8" onSubmit={handleSubmit(onSubmit)}>
      <span onClick={() => setOpen(false)} className="w-10 h-10 bg-black text-white rounded-full absolute right-7 leading-10  text-center cursor-pointer">
        X
      </span>
      <p className="text-center text-[35px] font-secondary font-semibold my-5">Sign Up</p>
      <h2 className="text-center text-base font-normal font-arial mb-8 ">Sign Up to new Tomedes account.</h2>
      <label className="mb-5 block">
        <input
          type="text"
          name="name"
          className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
          placeholder="User Name"
          {...register("name", { required: true })}
        />
        {errors.name && <span className="text-xs font-opensans -mt-1 font-normal text-left w-full text-red">{errorMessage}</span>}
        {checkSignUp === "Username Exists" ? <span className="text-xs -mt-1 font-opensans font-normal text-left w-full text-red">This UserName is already exists</span> : null}
      </label>
      <label className="mb-5 block">
        <input
          type="email"
          name="email"
          className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
          placeholder="Email address"
          {...register("email", {
            required: true,
            pattern: patternEmail,
          })}
        />
        {errors.email && <span className="text-xs font-opensans font-normal text-left w-full text-red">
         {errorMessageEmail} 
        </span>}
        {checkSignUp === "Email Exists" ? <span className="text-xs font-opensans font-normal text-left w-full text-red">This email is already exists</span> : null}
      </label>
      <label className="mb-5 block">
        <input
          type="password"
          name="password"
          className="text-lg font-arial font-normal px-4 w-full h-[50px] border border-quotetext-100 rounded-lg mb-1"
          placeholder="Password"
          {...register("password", { required: true })}
        />
        {errors.password && <span className="text-xs font-opensans font-normal text-left w-full text-red">
          {errorMessage}
        </span>}
      </label>
      <div className="flex items-center justify-between">
        <label>
          <input type="checkbox" name="" className="mr-2" />
          <span className="text-base font-arial font-normal">Remember me</span>
        </label>

        <p className="text-base font-arial font-normal text-[#273F77]"></p>
      </div>
      <input type="hidden" id="zc_gad" name="zc_gad" value="" />
      <button className="w-full h-[56px] bg-orange text-white font-arial text-xl my-8 rounded-lg font-bold" type="submit">
        {status === "loading" ? <Loader /> : "Sign up"}
      </button>
      {checkSignUp === "Resigered Successfully" ? (
        <span className="text-green-500 text-center">
          <i>Thank you!</i>
        </span>
      ) : null}
      <p className="text-base font-opensans text-center">
        Have account.{" "}
        <span className="cursor-pointer text-orange underline" onClick={() => setToggle(false)}>
          Sign In
        </span>
        {"  "}
        now.
      </p>
      <p className="text-base font-opensans text-center">Your information will be kept confidential</p>
    </form>
  );
}

export default SignUpPopUp;
