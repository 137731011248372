import React from "react";

export default function IsoCertifications({ backgroundCol, styleOne }) {
  
  return (
    <section
      className={`${
        backgroundCol ? backgroundCol : "bg-quoteBackground"
      } pb-24`}
    >
      {styleOne == "1" ? (
        <div className=" px-4 flex items-center justify-center flex-col gap-y-10">
          <h2 className=" text-3xl md:text-[35px] text-center font-secondary font-semibold sm:w-[723px] leading-relaxed text-midBlue">
            <span className="">ISO Certifications </span> for Enhanced
            Translation Quality
          </h2>
          <ul className="w-auto gap-x-8 flex lg:flex-row flex-col justify-between items-center lg:relative pt-6 lg:pt-0">
            <li className="logos flex flex-col items-center w-[186px] pt-6 lg:pt-0">
              <img src=" https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/iso.png" alt="" className="lg:pb-12" />
              <div className=" lg:absolute lg:top-[6rem] w-[186px]">
                <p className="text-[14px] text-midBlue pt-4  text-center font-bold">
                  ISO 9001:2015{" "}
                </p>
                <p className="text-[14px] text-midBlue text-center font-semibold">
                  Quality Management Systems
                </p>
              </div>
            </li>
            <li className="logos flex flex-col items-center w-[186px] pt-6 lg:pt-0">
              <img src="https://tomedes.gumlet.io/assets/new-assets/ISO_2007.svg" alt="" className="lg:pb-12" />
              <div className=" lg:absolute lg:top-[6rem] w-[186px]">
                <p className="text-[14px] text-midBlue pt-4  text-center font-bold">
                  ISO 18587:2017{" "}
                </p>
                <p className="text-[14px] text-midBlue text-center font-semibold">
                  Post-editing Machine Translation Output
                </p>
              </div>
            </li>
            <li className="logos flex flex-col items-center w-[186px] pt-6 lg:pt-0 sm:pb-0 pb-4">
              <img src="https://tomedes.gumlet.io/assets/new-assets/ISO_17100.svg" alt="" className="lg:pb-12" />
              <div className=" lg:absolute lg:top-[6rem] w-[174px]">
                <p className="text-[14px] text-midBlue pt-4  text-center font-bold">
                  ISO 17100:2015{" "}
                </p>
                <p className="text-[14px] text-midBlue text-center font-semibold">
                  Translation Services Quality
                </p>
              </div>
            </li>
            <li className="logos flex flex-col items-center w-[186px]">
              <img src="https://tomedes.gumlet.io/assets/images/new-homepage-assets/accreditation-icons/ata.png" alt="" className="lg:pb-12" />
              <div className="  lg:absolute lg:top-[6rem] w-[146px]">
                <p className="text-[14px] text-midBlue pt-4  text-center font-bold ">
                  ATA Corporate
                </p>
                <p className="text-[14px] text-midBlue text-center  font-semibold">
                  Member Number 272444
                </p>
              </div>
            </li>
          </ul>
        </div>
      ) : (
        ""
      )}
    </section>
  );
}
