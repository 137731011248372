import { endpoints } from "utils/endpoints";
import http from "utils/http";
import { useQuery } from "react-query";

export const UseDeleteFiles = (id) => {
  const newEndpoints = endpoints.quotes.deleteFile.replace(
    "/api/v1/delete-document?id={9}",
    `/api/v1/delete-document?id=${id}`
  );

  return http().get(newEndpoints);
};
